/* types imports */
import type { AxiosResponse } from "axios";
import type { ArtistEntity } from "./artist";
import type { UserEntity } from "./user";

/* project files imports */
import api from "utils/api";

export type GameEntity = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  user?: UserEntity;
  fuckedArtist?: ArtistEntity;
  marriedArtist?: ArtistEntity;
  killedArtist?: ArtistEntity;
};

type CreateGameDataProps = {
  hash: string;
  fuckedArtistId: ArtistEntity["id"];
  marriedArtistId: ArtistEntity["id"];
  killedArtistId: ArtistEntity["id"];
};

/**
 * Create new game for user
 * @param {CreateGameDataProps} data
 * @returns {Promise<Game>}
 */
const createGame = (data: CreateGameDataProps): Promise<GameEntity> => {
  return api.post("/games", data)
    .then((res: AxiosResponse) => res.data);
};

type GetManyGamesParams = {
  pageNumber: string;
  itemsPerPage: string;
};

export type GetManyGamesResponse = {
  pageNumber: number;
  itemsPerPage: number;
  totalPages: number;
  items: GameEntity[];
};

/**
 * Get user's game history
 * @param {GetManyGamesParams} params
 * @returns {Promise<GetManyGamesResponse>}
 */
const getManyGames = (params: GetManyGamesParams): Promise<GetManyGamesResponse> => {
  return api.get(`/games?${new URLSearchParams(params).toString()}`)
    .then((res: AxiosResponse) => res.data);
};

export default {
  createGame,
  getManyGames
};
