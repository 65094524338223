/* eslint-disable max-len */
/* modules imports */
import styled from "styled-components";

export const StyledModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Content = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
  border: 1px solid var(--color-red);
  border-radius: 8px;
  background-color: var(--color-white);
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  padding: 12px;
`;
