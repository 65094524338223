/* modules imports */
import styled, { css, keyframes } from "styled-components";

/* project files imports */
import { SnackbarType } from "./constants";
import { devices } from "constants/style";
import { ReactComponent as CloseIcon } from "assets/images/icon-x.svg";

const fadeIn = keyframes`
  from { bottom: 0; opacity: 0; }
  to { bottom: 25px; opacity: 1; }
`;

const fadeOut = keyframes`
  from { bottom: 25px; opacity: 1; }
  to { bottom: 0; opacity: 0; }
`;

const fadeInDesktop = keyframes`
  from { top: 0; opacity: 0; }
  to { top: 30px; opacity: 1; }
`;

const fadeOutDesktop = keyframes`
  from { top: 30px; opacity: 1; }
  to { top: 0; opacity: 0; }
`;

type StyledSnackbarProps = {
  isOpen: boolean;
  timeout: number;
  type: SnackbarType;
};

export const StyledSnackbar = styled.div<StyledSnackbarProps>`
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 220px;
  max-width: 340px;
  min-height: 60px;
  border-radius: 16px;
  background-color: var(--color-white);
  z-index: 10;

  @media ${devices.sm} {
    max-width: 400px;
    min-height: 75px;
    border-radius: 17px;
  }

  @media ${devices.lg} {
    top: 30px;
    bottom: unset;
    right: 30px;
    width: unset;
    left: unset;
    transform: unset;
  }

  ${({ type }) => {
    switch (type) {
      case SnackbarType.ERROR:
        return css`
          color: var(--color-red);
          border: 2px solid var(--color-red);

          & svg path {
            fill: var(--color-red);
          }
        `;
      case SnackbarType.INFO:
      default:
        return css`
          color: var(--color-black);
          border: 2px solid var(--color-black);

          & svg path {
            fill: var(--color-black);
          }
        `;
    }
  }}

  ${({ isOpen, timeout }) => isOpen
    ? css`
      visibility: visible;
      animation: ${fadeIn} 0.5s, ${fadeOut} 0.5s ${(timeout - 500) / 1000}s;

      @media ${devices.lg} {
        animation: ${fadeInDesktop} 0.5s, ${fadeOutDesktop} 0.5s ${(timeout - 500) / 1000}s;
      }
    `
    : "visibility: hidden;"}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 14px 16px;
  font-size: 14px;

  @media ${devices.sm} {
    padding: 18px 20px;
    font-size: 16px;
  }
`;

export const DateTime = styled.div`
  font-size: 10px;
  padding-bottom: 4px;

  @media ${devices.sm} {
    font-size: 12px;
    padding-bottom: 5px;
  }
`;

export const Message = styled.span`
  white-space: pre-line;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 14px;
  transform: scale(0.5);
  cursor: pointer;

  @media ${devices.sm} {
    top: 14px;
    right: 17px;
    transform: scale(0.6);
  }
`;
