/* modules imports */
import { useEffect } from "react";
import axios from "axios";

/* types imports */
import type { FC, PropsWithChildren } from "react";
import type { AxiosError } from "axios";

/* project files imports */
import useAuth from "hooks/useAuth";
import { Status as AuthStatus } from "constants/auth";

/**
 * Axios instance for back-end requests
 */
const instance = axios.create({
  baseURL: process.env.REACT_APP__API__BASE_URL,
  withCredentials: true,
  timeout: 10000,
  headers: { "Content-Type": "application/json" }
});

/**
 * Component that allows the use of hooks for axios interceptor definitions
 * @param {PropsWithChildren}
 * @returns {React.ReactNode}
 */
const AxiosInterceptor:FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      // 2XX responses interceptor
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (res: any) => {
        return res;
      },

      // 3XX, 4XX and 5XX responses interceptor
      (err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setStatus(AuthStatus.ERRORED);
        }

        return Promise.reject(err);
      }
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [auth]);

  return <>{children}</>;
};

export { AxiosInterceptor };
export default instance;
