/**
 * Make sure a string doesn't exceed a certain length by slicing
 * extra characters and replacing them by "..."
 * @param {string} string The string that will be processed if needed
 * @param {number} maxLength The maximum string length
 * @returns {string} The input string, or a new string limited in size
 */
export const limitSize = (string?: string, maxLength = 14): string => {
  if (!string) return "";
  else if (string.length <= maxLength + 3) return string;

  return `${string.slice(0, maxLength)?.trim()}...`;
};

export default { limitSize };
