/* modules imports */
import ReactDOM from "react-dom";
import { useEffect } from "react";

/* types imports */
import type { FC, HTMLAttributes } from "react";

/* project files imports */
import Tab, { TabSize } from "../../Tab";

/* styles imports */
import { Content, StyledModal, TabsWrapper } from "./index.styles";

type TabProps = {
  icon: string;
  label: string;
  onClick: () => void;
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tabs: TabProps[];
  selectedIndex: number;
} & HTMLAttributes<HTMLElement>;

const Modal:FC<ModalProps> = ({
  isOpen = false,
  onClose,
  tabs,
  selectedIndex
}) => {
  const closeOnEscapeKeyDown = (e: { charCode: number; keyCode: number }): void => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  }, []);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <StyledModal onClick={onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <TabsWrapper>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              icon={tab.icon}
              isSelected={index === selectedIndex}
              label={tab.label}
              onClick={tab.onClick}
              size={TabSize.SMALL}
            />
          ))}
        </TabsWrapper>
      </Content>
    </StyledModal>,
    document.getElementById("root")!
  );
};

export default Modal;
