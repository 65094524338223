/* modules imports */
import { css } from "styled-components";

export default css`
  html, body {
    font-family: var(--font-content);
    letter-spacing: -.1em;
    color: var(--color-black);
    background: var(--color-white);
  }

  body > #root > div {
    height: 100vh; /* fallback */
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
  }
`;
