/* modules imports */
import { Navigate, Route, Routes } from "react-router-dom";

/* types imports */
import type { FC } from "react";

/* project files imports */
import useAuth from "hooks/useAuth";
import { Status as AuthStatus } from "constants/auth";
import LoginPage from "pages/Login";
import PlayPage from "pages/Play";

const App:FC = () => {
  const auth = useAuth();

  return (
    <Routes>
      <Route
        element={
          auth.status === AuthStatus.LOADING
            ? null
            : auth.status === AuthStatus.AUTHORIZED
              ? <PlayPage />
              : <LoginPage authStatus={auth.status} />
        }
        path="/"
      />
      <Route element={<Navigate replace to="/" />} path="*" />
    </Routes>
  );
};

export default App;
