/* modules imports */
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

/* project files imports */
import { AuthProvider } from "hooks/useAuth";
import { AxiosInterceptor } from "utils/api";
import Styles from "styles";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <AxiosInterceptor>
      <BrowserRouter>
        <Styles />
        <App />
      </BrowserRouter>
    </AxiosInterceptor>
  </AuthProvider>
);
