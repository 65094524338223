const locale: Intl.LocalesArgument = "en-US";

/**
 * Turn a date into a string with the following format:
 * 12/25/22, 1:23 PM
 * @param {Date} date The date that will be processed if needed
 * @returns {string} The formatted version of the input date
 */
export const toSmallDateTime = (date: Date) => {
  if (!date) return "";

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric"
  };

  return new Date(date).toLocaleString(locale, options);
};

export default { toSmallDateTime };
