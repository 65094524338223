/* types imports */
import type { FC } from "react";
import type { ArtistEntity } from "services/artist";

/* project files imports */
import Emoji, { EmojiSize } from "components/Emoji";
import useAuth from "hooks/useAuth";
import useResponsivity from "hooks/useResponsivity";
import { limitSize } from "utils/string";
import {
  ActionLabels,
  Actions,
  ActionsEmojisMapping,
  Emojis
} from "constants/game";

/* styles imports */
import {
  AnimatedQuestionMark,
  Bold,
  BoxWrapper,
  Button,
  ButtonsGroup,
  Content,
  EmojiWrapper,
  Image,
  ImageWrapper,
  TextResult,
  Title
} from "./index.styles";

/* styles imports */

type PlayCardProps = {
  artist?: ArtistEntity;
  choice?: Actions | null;
  cardIndex: number;
  handleChoiceSelection?: (action: Actions, cardIndex: number) => void;
  isPlayAgainView?: boolean;
};

const PlayCard:FC<PlayCardProps> = ({
  artist,
  choice,
  cardIndex,
  handleChoiceSelection,
  isPlayAgainView = false
}) => {
  const { user } = useAuth();
  const { isDesktop } = useResponsivity();

  let artistProfile = artist?.profiles
    ?.find(({ service }) => service === user?.service);

  // this is a bit hacky: use a fallback profile if
  // no image exists for user service profile
  if (!artistProfile?.profilePicture && (artist?.profiles?.length || []) > 1) {
    artistProfile = artist?.profiles?.find(({ profilePicture }) => !!profilePicture);
  }

  const actions = [
    { action: Actions.FUCK, emoji: Emojis.SMIRKING_FACE },
    { action: Actions.MARRY, emoji: Emojis.RING },
    { action: Actions.KILL, emoji: Emojis.KITCHEN_KNIFE }
  ];

  const choiceIndex = actions
    .findIndex(({ action }) => action === choice);

  return (
    <Content cardIndex={cardIndex} hasAnimation={!!artist && !isPlayAgainView}>
      <BoxWrapper
        alignItems="flex-end"
        isHidden={!artist}
      >
        {!isPlayAgainView && (
          <Title>
            {limitSize(artist?.name, isDesktop ? 17 : 10)}
          </Title>
        )}
      </BoxWrapper>

      <ImageWrapper hasBorder>
        {artistProfile
          ? <Image image={artistProfile.profilePicture} />
          : <AnimatedQuestionMark cardIndex={cardIndex}>?</AnimatedQuestionMark>}
      </ImageWrapper>

      <BoxWrapper
        alignItems={handleChoiceSelection && isDesktop ? "center" : "flex-start"}
        isHidden={!artist}
      >
        {handleChoiceSelection && (
          <ButtonsGroup>
            {actions.map((action, index) => (
              <Button
                key={index}
                onClick={() => handleChoiceSelection(action.action, cardIndex)}
              >
                <EmojiWrapper isSelected={choiceIndex === index}>
                  <Emoji
                    emoji={action.emoji}
                    size={isDesktop ? EmojiSize.LARGE : EmojiSize.MEDIUM}
                  />
                </EmojiWrapper>
              </Button>
            ))}
          </ButtonsGroup>
        )}

        {isPlayAgainView && actions[cardIndex].action && (
          <TextResult>
            {ActionLabels.get(actions[cardIndex].action)}{" "}
            {
              isDesktop
                ? null
                : <Emoji
                  emoji={ActionsEmojisMapping.get(actions[cardIndex].action)}
                  size={isDesktop ? EmojiSize.MEDIUM : EmojiSize.SMALL}
                />
            }
            <br />
            <Bold>{limitSize(artist?.name, isDesktop ? 9 : 11)}{" "}</Bold>
            {
              isDesktop
                ? <Emoji
                  emoji={ActionsEmojisMapping.get(actions[cardIndex].action)}
                  size={isDesktop ? EmojiSize.MEDIUM : EmojiSize.SMALL}
                />
                : null
            }
          </TextResult>
        )}
      </BoxWrapper>
    </Content>
  );
};

export default PlayCard;
