/* modules imports */
import { css } from "styled-components";

export default css`
  @font-face {
    font-family: "FT88 Bold";
    src: url("../fonts/ft88/otf/ft88-bold.otf") format("opentype"),
      url("../fonts/ft88/ttf/ft88-bold.ttf") format("truetype"),
      url("../fonts/ft88/webfonts/ft88-bold.woff") format("woff"),
      url("../fonts/ft88/webfonts/ft88-bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "FT88";
    src: url("../fonts/ft88/otf/ft88-regular.otf") format("opentype"),
      url("../fonts/ft88/ttf/ft88-regular.ttf") format("truetype"),
      url("../fonts/ft88/webfonts/ft88-regular.woff") format("woff"),
      url("../fonts/ft88/webfonts/ft88-regular.woff2") format("woff2");
  }
`;
