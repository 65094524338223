/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC } from "react";
import type { Actions } from "constants/game";
import type { GetManyArtistsResponse } from "services/artist";
import type { GameEntity } from "services/game";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import BoxWrapper from "../BoxWrapper";
import Card from "../Card";
import artistService from "services/artist";
import Button, { ButtonSize, ButtonVariant } from "components/Button";
import Snackbar, { SnackbarType } from "components/Snackbar";

/* styles imports */
import { ButtonText, ButtonsGroup, CardWrapper, Main, Text } from "./index.styles";

type PlayAgainViewProps = {
  gameResult: GameEntity;
  setArtists: (artists: GetManyArtistsResponse | null) => void;
  setChoices: (choices: (Actions | null)[]) => void;
  setGameResult: (gameResult: GameEntity | null) => void;
};

const PlayAgainView:FC<PlayAgainViewProps> = ({
  gameResult,
  setArtists,
  setChoices,
  setGameResult
}) => {
  const ref = useRef<SnackbarRefProps>(null);

  const handlePlayAgainButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    artistService.getManyArtists()
      .then((results) => {
        setChoices([null, null, null]);
        setGameResult(null);
        setArtists(results);
      })
      .catch((err) => ref.current?.open(err?.response?.data?.message));
  };

  return (
    <Main>
      <BoxWrapper alignItems="flex-end">
        <Text>
          You decided to...
        </Text>
      </BoxWrapper>
      <CardWrapper>
        {
          [
            gameResult.fuckedArtist,
            gameResult.marriedArtist,
            gameResult.killedArtist
          ].map((artist, index) => (
            <Card
              key={index}
              artist={artist}
              cardIndex={index}
              isPlayAgainView
            />
          ))
        }
      </CardWrapper>
      <BoxWrapper alignItems="flex-end">
        <ButtonsGroup>
          <Button
            onClick={handlePlayAgainButton}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.OUTLINED}
          >
            <ButtonText>
              Play again
            </ButtonText>
          </Button>
          <Button
            onClick={
              () => {
                window.open(
                  `${process.env.REACT_APP__API__BASE_URL}/s/games/${gameResult.id}`,
                  "_blank"
                );
              }
            }
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.OUTLINED_PINK}
          >
            <ButtonText>
              Share
            </ButtonText>
          </Button>
        </ButtonsGroup>
      </BoxWrapper>

      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Main>
  );
};

export default PlayAgainView;
