/* modules imports */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* types imports */
import type { FC } from "react";

/* project files imports */
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import useAuth from "hooks/useAuth";
import useResponsivity from "hooks/useResponsivity";
import ModalAbout from "components/Modal/About";
import ModalHistory from "components/Modal/History";
import ModalProfile from "components/Modal/Profile";
import IconPlay from "assets/images/icon-play.svg";
import IconProfile from "assets/images/icon-profile.svg";
import IconClock from "assets/images/icon-clock.svg";
import IconAbout from "assets/images/icon-about.svg";
import IconCross from "assets/images/icon-cross.svg";

const Navigation:FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { isDesktop } = useResponsivity();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isModalProfileOpen, setIsModalProfileOpen] = useState<boolean>(false);
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState<boolean>(false);
  const [isModalAboutOpen, setIsModalAboutOpen] = useState<boolean>(false);

  const handleProfileOpening = () => {
    setIsModalProfileOpen(true);
    setSelectedIndex(1);
  };

  const handleProfileClosing = () => {
    setIsModalProfileOpen(false);
    setSelectedIndex(0);
  };

  const handleHistoryOpening = () => {
    setIsModalHistoryOpen(true);
    setSelectedIndex(2);
  };

  const handleHistoryClosing = () => {
    setIsModalHistoryOpen(false);
    setSelectedIndex(0);
  };

  const handleAboutOpening = () => {
    setIsModalAboutOpen(true);
    setSelectedIndex(3);
  };

  const handleAboutClosing = () => {
    setIsModalAboutOpen(false);
    setSelectedIndex(0);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const tabs = [
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    { label: "Play", icon: IconPlay, onClick: () => {} },
    { label: "Profile", icon: IconProfile, onClick: handleProfileOpening },
    { label: "History", icon: IconClock, onClick: handleHistoryOpening },
    { label: "About", icon: IconAbout, onClick: handleAboutOpening },
    { label: "Logout", icon: IconCross, onClick: handleLogout }
  ];

  return (
    <>
      {isDesktop
        ? <DesktopMenu selectedIndex={selectedIndex} tabs={tabs} />
        : <MobileMenu selectedIndex={selectedIndex} tabs={tabs} />}
      <ModalProfile
        isOpen={isModalProfileOpen}
        onClose={handleProfileClosing}
      />
      <ModalHistory
        isOpen={isModalHistoryOpen}
        onClose={handleHistoryClosing}
      />
      <ModalAbout
        isOpen={isModalAboutOpen}
        onClose={handleAboutClosing}
      />
    </>
  );
};

export default Navigation;
