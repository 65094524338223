/* eslint-disable max-len */
/* modules imports */
import { useEffect, useState } from "react";

/* types imports */
import type { FC, HTMLAttributes } from "react";

/* project files imports */
import Modal from "../index";
import Link from "components/Link";

/* styles imports */
import { Body, ContentWrapper, Header, StyledUl, Title } from "./index.styles";

type ModalAboutProps = {
  isOpen: boolean;
  onClose: () => void;
} & HTMLAttributes<HTMLElement>;

const ModalAbout:FC<ModalAboutProps> = (props) => {
  const [email, setEmail] = useState<string>("emusk@spacex.com");

  useEffect(() => {
    setTimeout(
      (): void => { setEmail("hello@fmk.cool"); },
      1500 // 1.5sec
    );
  }, []);

  return (
    <Modal {...props}>
      <ContentWrapper>
        <Header>
        About
        </Header>
        <Body>
          This is a version of <Link to="https://en.wikipedia.org/wiki/Fuck,_marry,_kill">Fuck, Marry, Kill</Link>,
          the famous forced-choice question-and-answer game, with your favorite musical artists of the moment.
          <br />
          <br />

          <Title>How it works</Title>
          <StyledUl>
            <li>When you sign up, your recent most listened to artists are fetched.</li>
            <StyledUl>
              <li>50 artists for Spotify</li>
              <li>20 artists for Deezer</li>
              <li>50 artists for Last.fm</li>
            </StyledUl>
            <li>When you click on the "Play" button, it generates a random trio among your artists.</li>
            <li>Once you have made up your choice, it is saved and now available in your history. Note that this trio will never be proposed again.</li>
            <li>You can update your artists with your latest most listened ones from your profile.</li>
          </StyledUl>

          <Title>Privacy Policy</Title>
          <StyledUl>
            <li>Personal information collected:</li>
            <StyledUl>
              <li>account name</li>
              <li>account email</li>
              <li>ip address</li>
            </StyledUl>
            <li>Cookies and third-party technologies:</li>
            <StyledUl>
              <li>cookies are exclusively used to authenticate users (session cookies)</li>
              <li>none of your data is shared with third parties</li>
            </StyledUl>
            <li>Data access and control:</li>
            <StyledUl>
              <li>if you want to delete your account, or have a request related to your personal information, please contact us at {email}</li>
            </StyledUl>
          </StyledUl>

          <Title>Credits</Title>
          <StyledUl>
            <li>Designed by <Link to="https://www.instagram.com/pela.xyz/">pela</Link></li>
            <li>Typeface <Link to="https://www.velvetyne.fr/fonts/degheest/">FT88</Link> by Ange Degheest, Oriane Charvieux & Mandy Elbé</li>
            <li>Typeface <Link to="https://www.velvetyne.fr/fonts/mr-pixel/">Mister Pixel</Link> by Christophe Badani</li>
          </StyledUl>

          <Title>Contact</Title>
          <StyledUl>
            <li><Link to="https://www.instagram.com/anyo/">@anyo</Link></li>
            <li>{email}</li>
          </StyledUl>
        </Body>
      </ContentWrapper>
    </Modal>
  );
};

export default ModalAbout;
