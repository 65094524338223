/* modules imports */
import styled, { keyframes } from "styled-components";

/* project files imports */
import { devices } from "constants/style";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fadeIn = keyframes`
  100% { opacity: 1; }
`;

export const Text = styled.span`
  max-width: 250px;
  font-size: 16px;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} .15s forwards;
  animation-delay: .15s;

  @media ${devices.sm} {
    max-width: 450px;
    font-size: 18px;
  }

  @media ${devices.lg} {
    max-width: 600px;
    font-size: 20px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  @media ${devices.sm} { column-gap: 20px; }
  @media ${devices.lg} { column-gap: 35px; }
`;
