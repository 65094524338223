/* types imports */
import type { FC, HTMLAttributes, PropsWithChildren } from "react";

/* styles imports */
import { StyledHyperlink } from "./index.styles";

type LinkProps = {
  to: string;
} & PropsWithChildren & HTMLAttributes<HTMLElement>;

const Link:FC<LinkProps> = ({ children, to }) => {
  return (
    <StyledHyperlink
      href={to}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </StyledHyperlink>
  );
};

export default Link;
