/* modules imports */
import { useEffect } from "react";

/* types imports */
import type { FC, HTMLAttributes, PropsWithChildren } from "react";

/* project files imports */
import ReactPortal from "components/ReactPortal";

/* styles imports */
import { Content, StyledCloseIcon, StyledModal } from "./index.styles";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  width?: number;
  height?: number;
  paddingCloseIcon?: number;
} & PropsWithChildren & HTMLAttributes<HTMLElement>;

const Modal:FC<ModalProps> = ({
  children,
  isOpen = false,
  onClose,
  width = 700,
  height = 500,
  paddingCloseIcon = 30
}) => {
  const closeOnEscapeKeyDown = (e: { charCode: number; keyCode: number }): void => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  }, []);

  if (!isOpen) return null;

  return (
    <ReactPortal>
      <StyledModal onClick={onClose}>
        <Content
          height={height}
          onClick={(e) => e.stopPropagation()}
          width={width}
        >
          <StyledCloseIcon
            onClick={onClose}
            padding={paddingCloseIcon}
          />
          {children}
        </Content>
      </StyledModal>
    </ReactPortal>
  );
};

export default Modal;
