/* modules imports */
import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  height: inherit !important;
  top: 40px;
  left: 40px;
  display: flex;
  align-items: center;
  row-gap: 9px;
  z-index: 1;
`;

export const Logo = styled.img`
  width: 142px;
  padding-bottom: 10px;
`;
