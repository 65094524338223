/* modules imports */
import styled from "styled-components";

type BoxWrapperProps = {
  alignItems?: string;
  height?: number;
};

const BoxWrapper = styled.div<BoxWrapperProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: center;
  height: ${({ height }) => typeof height === "number" ? height : 50}px;
  width: 100%;
`;

export default BoxWrapper;
