/* modules imports */
import styled, { keyframes } from "styled-components";

/* project files imports */
import { devices } from "constants/style";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;

  @media ${devices.lg} { row-gap: 0; }
`;

const fadeIn = keyframes`
  100% { opacity: 1; }
`;

export const Text = styled.span`
  max-width: 275px;
  font-size: 16px;
  text-align: center;

  @media ${devices.sm} {
    max-width: 450px;
    font-size: 18px;
  }

  @media ${devices.lg} {
    max-width: 600px;
    font-size: 20px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  @media ${devices.sm} { column-gap: 20px; }
  @media ${devices.lg} { column-gap: 35px; }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  column-gap: 15px;
  opacity: 0;
  animation: ${fadeIn} .25s forwards;
  animation-delay: .25s;
`;

export const ButtonText = styled.span`
  font-family: var(--font-content-bold);
  font-size: 18px;

  @media ${devices.sm} { font-size: 20px; }
  @media ${devices.lg} { font-size: 22px; }
`;
