/* modules imports */
import styled from "styled-components";

/* project files imports */
import { devices } from "constants/style";
import { ReactComponent as CloseIcon } from "assets/images/icon-x.svg";

export const StyledModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

type ContentProps = {
  width: number;
  height: number;
};

export const Content = styled.div<ContentProps>`
  position: relative;
  width: 350px;
  height: 440px;
  min-height: 440px;
  border-radius: 25px;
  border: 2px solid var(--color-black);
  background-color: var(--color-white);

  @media ${devices.sm} {
    width: 450px;
    height: 570px;
    min-height: 570px;
    border-radius: 28px;
  }

  @media ${devices.lg} {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    min-height: ${({ height }) => height}px;
    border-radius: 31px;
  }
`;

type StyledCloseIconProps = {
  padding: number;
};

export const StyledCloseIcon = styled(CloseIcon)<StyledCloseIconProps>`
  width: 20px;
  height: 20px;
  transform: scale(0.75);
  position: absolute;
  top: 16px;
  right: 14px;
  cursor: pointer;
  z-index: 2;

  @media ${devices.sm} {
    transform: scale(0.85);
    top: 18px;
    right: 16px;
  }

  @media ${devices.lg} {
    transform: scale(1);
    top: ${({ padding }) => padding}px;
    right: ${({ padding }) => padding}px;
  }
`;
