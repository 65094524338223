/* modules imports */
import styled from "styled-components";

/* project files imports */
import { devices } from "constants/style";

export const Content = styled.div`
  width: 100%;
  height: 389px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media ${devices.sm} {
    height: 518px;
  }
`;

export const Card = styled.div`
  width: 100%;
  padding: 18px 0;
  font-size: 15px;
  border-bottom: 2px solid var(--color-black);

  @media ${devices.sm} {
    padding: 6px 0;
  }
`;

const CardEntry = styled.div`
  padding: 0 15px;
`;

export const DateTime = styled(CardEntry)`
  width: 100%;
  height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 4px;
  font-size: 14px;

  @media ${devices.sm} {
    padding-bottom: 2px;
  }
`;

export const Artist = styled(CardEntry)`
  height: 1.5em;
`;

export const ShareButtonWrapper = styled(Artist)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NoHistory = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-grey);
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 47px;
  border-top: 2px solid var(--color-black);
  font-size: 15px;
`;

type NavButtonProps = {
  isHidden: boolean;
};

const NavButton = styled.div<NavButtonProps>`
  position: absolute;
  cursor: pointer;
  display: ${({ isHidden }) => isHidden ? "none" : "block"};
  top: 13px;
  font-size: 18px;
`;

export const PrevButton = styled(NavButton)`
  left: 30px;
`;

export const NextButton = styled(NavButton)`
  right: 30px;
`;
