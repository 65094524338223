/* types imports */
import type { FC, HTMLAttributes } from "react";
import type { GetManyGamesResponse } from "services/game";

/* project files imports */
import Emoji, { EmojiSize } from "components/Emoji";
import Button, { ButtonSize, ButtonVariant } from "components/Button";
import useAuth from "hooks/useAuth";
import { limitSize } from "utils/string";
import { toSmallDateTime } from "utils/date";
import { Emojis } from "constants/game";

/* styles imports */
import {
  Footer,
  NextButton,
  PrevButton,
  ShareButtonWrapper,
  Table,
  Tbody,
  Td,
  TdNoHistory,
  Th,
  Tr,
  TrNoHistory
} from "./index.styles";

type ModalHistoryDesktopProps = {
  games: GetManyGamesResponse | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  handlePrevButton: () => void;
  handleNextButton: () => void;
} & HTMLAttributes<HTMLElement>;

const ModalHistoryDesktop:FC<ModalHistoryDesktopProps> = ({
  games,
  hasPrevPage,
  hasNextPage,
  handlePrevButton,
  handleNextButton
}) => {
  const { user } = useAuth();

  return (
    <>
      <Table>
        <thead>
          <Tr>
            <Th>date</Th>
            <Th><Emoji emoji={Emojis.SMIRKING_FACE} isSelfCentered size={EmojiSize.MEDIUM} /></Th>
            <Th><Emoji emoji={Emojis.RING} isSelfCentered size={EmojiSize.MEDIUM} /></Th>
            <Th><Emoji emoji={Emojis.KITCHEN_KNIFE} isSelfCentered size={EmojiSize.MEDIUM} /></Th>
          </Tr>
        </thead>
        <Tbody>
          {
            games?.items?.length
              ? games.items.map((entry, index) => (
                <Tr key={index}>
                  <Td>{toSmallDateTime(entry.createdAt)}</Td>
                  <Td>{limitSize(entry.fuckedArtist?.name)}</Td>
                  <Td>{limitSize(entry.marriedArtist?.name)}</Td>
                  <Td>
                    <ShareButtonWrapper>
                      {limitSize(entry.killedArtist?.name)}
                      <Button
                        onClick={
                          () => {
                            window.open(
                              `${process.env.REACT_APP__API__BASE_URL}/s/games/${entry.id}`,
                              "_blank"
                            );
                          }
                        }
                        size={ButtonSize.SMALL}
                        variant={ButtonVariant.PINK}
                      >
                          Share
                      </Button>
                    </ShareButtonWrapper>
                  </Td>
                </Tr>
              ))
              : (
                <TrNoHistory>
                  <TdNoHistory>
                    You have to play at least 1 game to see your history!
                  </TdNoHistory>
                </TrNoHistory>
              )
          }
        </Tbody>
      </Table>
      <Footer>
        <PrevButton
          isHidden={!hasPrevPage}
          onClick={handlePrevButton}
        >
          {"<"} prev
        </PrevButton>
        total games played: {user?.totalGamesPlayed || 0}
        <NextButton
          isHidden={!hasNextPage}
          onClick={handleNextButton}
        >
          next {">"}
        </NextButton>
      </Footer>
    </>
  );
};

export default ModalHistoryDesktop;
