/* modules imports */
import styled, { keyframes } from "styled-components";

/* project files imports */
import { devices } from "constants/style";
import Button from "components/Button";

export const Content = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  text-align: center;
  font-family: var(--font-content);
  font-size: 16px;
  color: var(--color-grey);

  @media ${devices.sm} {
    max-width: 640px;
    row-gap: 20px;
    font-size: 18px;
  }

  @media ${devices.lg} {
    max-width: 700px;
    font-size: 20px;
  }
`;

export const Bold = styled.b`
  all: unset;
  font-family: var(--font-content-bold);
`;

export const StyledSpan = styled.span`
  font-size: 14px;

  @media ${devices.sm} { font-size: 16px; }
  @media ${devices.lg} { font-size: 18px; }
`;

const fadeIn = keyframes`
  100% { opacity: 1; }
`;

export const StyledButton = styled(Button)`
  opacity: 0;
  animation: ${fadeIn} .15s forwards;
  animation-delay: .15s;
  font-family: var(--font-content-bold);
  font-size: 16px;

  @media ${devices.sm} { font-size: 18px; }
  @media ${devices.lg} { font-size: 20px; }
`;
