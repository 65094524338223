/* modules imports */
import { createPortal } from "react-dom";
import { useLayoutEffect, useState } from "react";

/* types imports */
import type { FC, PropsWithChildren } from "react";

const ReactPortal:FC<PropsWithChildren> = ({ children }) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const element = document.createElement("div");

    element.setAttribute("id", "modal"); // custom wrapperId here
    document.body.appendChild(element);

    setWrapperElement(element);

    return () => {
      if (element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, []);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export default ReactPortal;
