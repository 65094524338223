/* modules imports */
import styled from "styled-components";

/* project files imports */
import { devices } from "constants/style";

export const ContentWrapper = styled.div`
  padding: 15px;
  height: calc(100% - 30px);

  @media ${devices.sm} {
    padding: 22px;
    height: calc(100% - 44px);
  }

  @media ${devices.lg} {
    padding: 30px;
    height: calc(100% - 60px);
  }
`;

export const Header = styled.div`
  font-size: 24px;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  @media ${devices.lg} {
    font-size: 28px;
    padding-bottom: 13px;
  }

  @media ${devices.lg} {
    font-size: 32px;
    padding-bottom: 15px;
  }
`;

export const Body = styled.div`
  height: 90%;
  font-size: 16px;
  overflow: scroll;

  @media ${devices.lg} { font-size: 18px; }
`;

export const Title = styled.span`
  font-family: var(--font-content-bold);
  display: block;
`;

export const StyledUl = styled.ul`
  margin: 10px 0;
  padding-left: 25px;

  @media ${devices.lg} { padding-left: 30px; }
  @media ${devices.lg} { padding-left: 35px; }
`;
