/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC } from "react";
import type { UserEntity } from "services/user";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import { ServiceLabels } from "./constants";
import userService from "services/user";
import Snackbar, { SnackbarType } from "components/Snackbar";
import { ButtonSize, ButtonVariant } from "components/Button";
import Emoji, { EmojiSize } from "components/Emoji";
import { Emojis } from "constants/game";

/* styles imports */
import { Bold, Content, StyledButton, StyledSpan } from "./index.styles";

type MissingUserTopArtistsProps = {
  user: UserEntity | null;
  setUser: (user: UserEntity | null) => void;
};

const MissingUserTopArtists:FC<MissingUserTopArtistsProps> = ({ user, setUser }) => {
  const ref = useRef<SnackbarRefProps>(null);

  const handleRefreshUserData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    userService.refreshUserData()
      .then(setUser)
      .catch((err) => ref.current?.open(err?.response?.data?.message));
  };

  return (
    <Content>
      <span>
        Hi <Bold>{user?.name}</Bold>! You can't play yet, you haven't listened enough music on your
        {" "}{user?.service ? ServiceLabels.get(user?.service) : null}{" "}account lately. ):
      </span>
      {user?.canRefreshData
        ? (
          <StyledSpan>
            Maybe try updating your top artists by clicking the button!
            {" "}<Emoji emoji={Emojis.BACKHAND_INDEX_POINTING_DOWN} size={EmojiSize.SMALL} />
          </StyledSpan>
        )
        : <StyledSpan>Go listen to more music and come back in a few days!</StyledSpan>}
      {user?.canRefreshData
        ? (
          <StyledButton
            onClick={handleRefreshUserData}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.OUTLINED}
          >
            Refresh
          </StyledButton>
        )
        : null}
      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Content>
  );
};

export default MissingUserTopArtists;
