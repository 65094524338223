/* modules imports */
import styled, { css } from "styled-components";

/* project files imports */
import { ButtonSize, ButtonVariant } from "./constants";
import { devices } from "constants/style";

type StyledButtonProps = {
  variant?: string;
  size?: string;
};

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  font-family: var(--font-content);
  border: 1px solid var(--color-red);

  ${({ variant }) => {
    switch (variant) {
      case ButtonVariant.OUTLINED:
        return css`
          color: var(--color-red);
          background-color: transparent;

          &:hover {
            color: var(--color-white);
            background-color: var(--color-red);
          }
        `;
      case ButtonVariant.PINK:
        return css`
          color: var(--color-pink);
          background-color: transparent;
          font-family: var(--font-content-bold);
          /* border: 1px solid var(--color-pink); */
          border: 0;

          &:hover {
            color: var(--color-white);
            background-color: var(--color-pink);
            /* border: 1px solid var(--color-pink); */
          }
        `;
      case ButtonVariant.OUTLINED_PINK:
        return css`
          color: var(--color-pink);
          background-color: transparent;
          font-family: var(--font-content-bold);
          border: 1px solid var(--color-pink);

          &:hover {
            color: var(--color-white);
            background-color: var(--color-pink);
            border: 1px solid var(--color-pink);
          }
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case ButtonSize.LARGE:
        return css`
          width: 320px;
          height: 35px;
          border-radius: 6px;
          font-size: 15px;

          @media ${devices.sm} {
            width: 350px;
            height: 39px;
            border-radius: 7px;
            font-size: 16px;
          }

          @media ${devices.lg} {
            width: 380px;
            height: 42px;
            border-radius: 8px;
            font-size: 17px;
          }
        `;
      case ButtonSize.MEDIUM:
        return css`
          width: 160px;
          height: 42px;
          border-radius: 9px;
          font-size: 16px;

          @media ${devices.sm} {
            width: 170px;
            height: 44px;
            border-radius: 10px;
            font-size: 20px;
          }

          @media ${devices.lg} {
            width: 190px;
            height: 48px;
            border-radius: 11px;
            font-size: 24px;
          }
        `;
      case ButtonSize.SMALL:
        return css`
          width: 100px;
          height: 35px;
          border-radius: 7px;
          font-size: 16px;

          @media ${devices.sm} {
            height: 40px;
            font-size: 17px;
          }

          @media ${devices.lg} {
            width: 80px;
            height: 36px;
            border-radius: 8px;
            font-size: 18px;
          }
        `;
      case ButtonSize.TINY:
        return css`
          width: 75px;
          height: 2.5em;
          border-radius: 7px;
          font-size: 14px;
        `;
    }
  }}
`;
