/* types imports */
import type { FC, HTMLAttributes } from "react";

/* project files imports */
import { EmojiSize } from "./constants";
import { EmojiLabels, Emojis } from "constants/game";
import emojiSmirkingFace from "assets/images/smirking-face.png";
import emojiRing from "assets/images/ring.png";
import emojiKitchenKnife from "assets/images/kitchen-knife.png";
import emojiPartyPopper from "assets/images/party-popper.png";
import emojiBackhandIndexPointingDown from "assets/images/backhand-index-pointing-down.png";

/* styles imports */
import { StyledEmoji } from "./index.styles";

type EmojiProps = {
  emoji?: Emojis;
  size?: EmojiSize;
  isSelfCentered?: boolean;
} & HTMLAttributes<HTMLElement>;

const Emoji:FC<EmojiProps> = ({
  emoji,
  size = EmojiSize.LARGE,
  isSelfCentered = false
}) => {
  const image = {
    [Emojis.SMIRKING_FACE]: emojiSmirkingFace,
    [Emojis.RING]: emojiRing,
    [Emojis.KITCHEN_KNIFE]: emojiKitchenKnife,
    [Emojis.PARTY_POPPER]: emojiPartyPopper,
    [Emojis.BACKHAND_INDEX_POINTING_DOWN]: emojiBackhandIndexPointingDown
  };

  if (!emoji || !image[emoji]) return null;

  return (
    <StyledEmoji
      alt={`${EmojiLabels.get(emoji)} emoji`}
      draggable="false"
      isSelfCentered={isSelfCentered}
      size={size}
      src={image[emoji]}
    />
  );
};

export { EmojiSize };
export default Emoji;
