/* types imports */
import type { FC } from "react";

/* project files imports */
import { TabSize } from "./constants";

/* styles imports */
import { Content, Icon } from "./index.styles";

type TabProps = {
  icon: string;
  label: string;
  isSelected: boolean;
  size?: TabSize;
  onClick: () => void;
};

const Tab:FC<TabProps> = ({
  icon,
  label,
  isSelected,
  size = TabSize.LARGE,
  onClick
}) => {
  return (
    <Content
      isSelected={isSelected}
      onClick={onClick}
      size={size}
    >
      <Icon alt="icon" size={size} src={icon} /> {label}
    </Content>
  );
};

export { TabSize };
export default Tab;
