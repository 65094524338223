/* modules imports */
import styled from "styled-components";
import Lottie from "react-lottie-player";

/* project files imports */
import { devices } from "constants/style";

export const Content = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

type StyledLottieProps = {
  position: string;
};

export const StyledLottie = styled(Lottie)<StyledLottieProps>`
  position: absolute;
  width: 55px;
  height: 55px;

  ${({ position }) => position === "left"
    ? `
      bottom: 20px;
      left: 20px;
    ` : `
      top: 55px;
      right: 15px;
    `};

  @media ${devices.sm} {
    width: 65px;
    height: 65px;
  }

  @media ${devices.lg} {
    position: static;
    width: 100px;
    height: 100px;

    ${({ position }) => position === "left"
    ? `
        padding-top: 45vh;
        padding-right: 30px;
      ` : `
        padding-bottom: 45vh;
        padding-left: 30px;
      `};
  }
`;
