/* types imports */
import type { FC, HTMLAttributes } from "react";

/* project files imports */
import Tab from "../Tab";
import logo from "assets/images/logo-small.png";

/* styles imports */
import { Content, Logo } from "./index.styles";

type TabProps = {
  icon: string;
  label: string;
  onClick: () => void;
};

type DesktopMenuProps = {
  tabs: TabProps[];
  selectedIndex: number;
} & HTMLAttributes<HTMLElement>;

const DesktopMenu: FC<DesktopMenuProps> = ({ tabs, selectedIndex }) => {
  return (
    <Content>
      <Logo
        alt="fmk small logo"
        draggable={false}
        src={logo}
      />
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          icon={tab.icon}
          isSelected={index === selectedIndex}
          label={tab.label}
          onClick={tab.onClick}
        />
      ))}
    </Content>
  );
};

export default DesktopMenu;
