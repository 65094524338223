/* modules imports */
import styled, { css } from "styled-components";

/* project files imports */
import { TabSize } from "./constants";

type IconProps = {
  size: string;
};

export const Icon = styled.img<IconProps>`
  padding: 6px;
  pointer-events: none;

  ${({ size }) => {
    switch (size) {
      case TabSize.SMALL:
        return css`
          width: 12px;
          height: 12px;
        `;
      case TabSize.LARGE:
        return css`
          width: 15px;
          height: 15px;
        `;
    }
  }}
`;

type ContentProps = {
  isSelected: boolean;
  size: string;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  border: 1px solid var(--color-red);
  border-radius: 6px;
  background-color: var(--color-white);
  color: var(--color-red);
  cursor: pointer;
  user-select: none;

  ${({ isSelected }) => isSelected && `
    color: var(--color-white);
    background-color: var(--color-red);

    & ${Icon} {
      filter: brightness(0) invert(1);
    }
  `}

  ${({ size }) => {
    switch (size) {
      case TabSize.SMALL:
        return css`
          width: 128px;
          height: 23px;
          font-size: 14px;
        `;
      case TabSize.LARGE:
        return css`
          width: 150px;
          height: 25px;
          font-size: 15px;
        `;
    }
  }}

  &:hover {
    color: var(--color-white);
    background-color: var(--color-red);

    & ${Icon} {
      filter: brightness(0) invert(1);
    }
  }
`;
