/* project files imports */
import placeholderArtist1 from "assets/images/placeholder-artist-1.png";
import placeholderArtist2 from "assets/images/placeholder-artist-2.png";
import placeholderArtist3 from "assets/images/placeholder-artist-3.png";
import placeholderUser1 from "assets/images/placeholder-user-1.png";
import placeholderUser2 from "assets/images/placeholder-user-2.png";
import placeholderUser3 from "assets/images/placeholder-user-3.png";
import placeholderUser4 from "assets/images/placeholder-user-4.png";
import placeholderUser5 from "assets/images/placeholder-user-5.png";
import placeholderUser6 from "assets/images/placeholder-user-6.png";
import placeholderUser7 from "assets/images/placeholder-user-7.png";
import placeholderUser8 from "assets/images/placeholder-user-8.png";
import placeholderUser9 from "assets/images/placeholder-user-9.png";

/**
 * Pick and return a random string for artist profile picture placeholder
 * @returns {string} A random placeholder image as a string
 */
export const artistPicturePlaceholder = (): string => {
  const placeholders:string[] = [
    placeholderArtist1,
    placeholderArtist2,
    placeholderArtist3
  ];

  return placeholders[Math.floor(Math.random() * placeholders.length)];
};

/**
 * Pick and return a random string for user profile picture placeholder
 * @returns {string} A random placeholder image as a string
 */
export const profilePicturePlaceholder = (): string => {
  const placeholders:string[] = [
    placeholderUser1,
    placeholderUser2,
    placeholderUser3,
    placeholderUser4,
    placeholderUser5,
    placeholderUser6,
    placeholderUser7,
    placeholderUser8,
    placeholderUser9
  ];

  return placeholders[Math.floor(Math.random() * placeholders.length)];
};

export default {
  artistPicturePlaceholder,
  profilePicturePlaceholder
};
