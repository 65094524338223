/* modules imports */
import styled, { css } from "styled-components";

/* project files imports */
import { EmojiSize } from "./constants";

type StyledEmojiProps = {
  size: string;
  isSelfCentered: boolean;
};

export const StyledEmoji = styled.img<StyledEmojiProps>`
  user-select: none;

  ${({ size }) => {
    switch (size) {
      case EmojiSize.LARGE:
        return css`
          width: 25px;
          height: 25px;
        `;
      case EmojiSize.MEDIUM:
        return css`
          width: 20px;
          height: 20px;
        `;
      case EmojiSize.SMALL:
        return css`
          width: 16px;
          height: 16px;
          transform: translateY(2px);
        `;
    }
  }}

  ${({ isSelfCentered }) =>
    isSelfCentered
      ? css`
        position: absolute;
        top: 50%;
        margin-top: -10px;
      `
      : null
}
`;
