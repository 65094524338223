export const NB_ARTISTS_PER_GAME = 3;

export enum Actions {
  FUCK = "FUCK",
  MARRY = "MARRY",
  KILL = "KILL",
}

export const ActionLabels = new Map<Actions, string>([
  [Actions.FUCK, "fuck"],
  [Actions.MARRY, "marry"],
  [Actions.KILL, "kill"]
]);

export enum Emojis {
  SMIRKING_FACE = "SMIRKING_FACE",
  RING = "RING",
  KITCHEN_KNIFE = "KITCHEN_KNIFE",
  PARTY_POPPER = "PARTY_POPPER",
  BACKHAND_INDEX_POINTING_DOWN = "BACKHAND_INDEX_POINTING_DOWN"
}

export const EmojiLabels = new Map<Emojis, string>([
  [Emojis.SMIRKING_FACE, "smirking face"],
  [Emojis.RING, "ring"],
  [Emojis.KITCHEN_KNIFE, "kichen knife"],
  [Emojis.PARTY_POPPER, "party popper"],
  [Emojis.BACKHAND_INDEX_POINTING_DOWN, "backhand index pointing down"]
]);

export const ActionsEmojisMapping = new Map<Actions, Emojis>([
  [Actions.FUCK, Emojis.SMIRKING_FACE],
  [Actions.MARRY, Emojis.RING],
  [Actions.KILL, Emojis.KITCHEN_KNIFE]
]);

export default {
  NB_ARTISTS_PER_GAME,
  Actions,
  ActionLabels,
  Emojis,
  EmojiLabels,
  ActionsEmojisMapping
};
