/* modules imports */
import { createContext, useContext, useEffect, useState } from "react";

/* types imports */
import type { FC, PropsWithChildren } from "react";
import type { AxiosError } from "axios";
import type { UserEntity } from "services/user";

/* project files imports */
import authService from "services/auth";
import userService from "services/user";
import { Status as AuthStatus } from "constants/auth";

type AuthContextProps = {
  user: UserEntity | null;
  setUser: (user: UserEntity | null) => void;
  status: AuthStatus;
  setStatus: (status: AuthStatus) => void;
  logout: () => void;
};

const AuthContext = createContext({} as AuthContextProps);

const useAuth = (): AuthContextProps => {
  const [user, setUser] = useState<UserEntity | null>(null);
  const [status, setStatus] = useState<AuthStatus>(AuthStatus.LOADING);

  const errorHandler = (err: AxiosError) => {
    if (err.response?.status === 401) {
      setStatus(AuthStatus.UNAUTHORIZED);
    } else {
      setStatus(AuthStatus.ERRORED);
    }
  };

  useEffect(() => {
    userService.getUserData()
      .then((user) => {
        setUser(user);
        setStatus(AuthStatus.AUTHORIZED);
      })
      .catch(errorHandler);
  }, []);

  return {
    user,
    setUser,
    status,
    setStatus,
    logout() {
      authService.logout()
        .then(() => setStatus(AuthStatus.UNAUTHORIZED))
        .catch(errorHandler);
    }
  };
};

export const AuthProvider:FC<PropsWithChildren> = ({ children }) => {
  const context = useAuth();

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);
