/* modules imports */
import { useEffect, useState } from "react";

/* project files imports */
import { sizes } from "constants/style";

type ResponsivityContextProps = {
  isDesktop: boolean;
  isMobile: boolean;
};

/**
 * Watch window resizing and tell if it seems to be a mobile device
 * @returns {ResponsivityContextProps}
 */
const useResponsivity = (): ResponsivityContextProps => {
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= sizes.lg);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < sizes.sm);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsDesktop(window.innerWidth >= sizes.lg);
      setIsMobile(window.innerWidth < sizes.sm);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { isDesktop, isMobile };
};

export default useResponsivity;
