/* types imports */
import type { FC, HTMLAttributes, PropsWithChildren } from "react";

/* project files imports */
import { ButtonSize, ButtonVariant } from "./constants";

/* styles imports */
import { StyledButton } from "./index.styles";

type ButtonProps = {
  variant: ButtonVariant;
  size: ButtonSize;
} & PropsWithChildren & HTMLAttributes<HTMLElement>;

const Button:FC<ButtonProps> = ({ children, variant, size, ...props }) => {
  return (
    <StyledButton
      size={size}
      variant={variant}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export { ButtonVariant, ButtonSize };
export default Button;
