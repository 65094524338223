/**
 * Tailwind default breakpoints, inspired by common device resolutions
 * cf. https://tailwindcss.com/docs/screens
 */
export const sizes = {
  sm: 640, /* small devices and most phones */
  lg: 1150 /* smaller desktop viewpoints (tablets...) */
};

export const devices = Object.entries(sizes).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: `(min-width: ${value}px)` }),
  sizes
);
