/* modules imports */
import { useRef, useState } from "react";
import { useEffect } from "react";

/* types imports */
import type { FC, HTMLAttributes } from "react";
import type { GetManyGamesResponse } from "services/game";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import Modal from "../index";
import ModalHistoryDesktop from "./Desktop";
import ModalHistoryMobile from "./Mobile";
import useResponsivity from "hooks/useResponsivity";
import gameService from "services/game";
import Snackbar, { SnackbarType } from "components/Snackbar";

type ModalHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
} & HTMLAttributes<HTMLElement>;

const ModalHistory:FC<ModalHistoryProps> = ({ isOpen, onClose }) => {
  const { isDesktop, isMobile } = useResponsivity();
  const ref = useRef<SnackbarRefProps>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [games, setGames] = useState<GetManyGamesResponse | null>(null);

  useEffect(() => {
    if (isOpen) {
      gameService.getManyGames({
        pageNumber: String(pageNumber),
        itemsPerPage: String(isDesktop ? 10 : isMobile ? 3 : 5)
      })
        .then(setGames)
        .catch((err) => ref.current?.open(err?.response?.data?.message));
    }

    return () => {
      if (!isOpen) {
        setPageNumber(1);
        setGames(null);
      }
    };
  }, [isDesktop, isMobile, isOpen, pageNumber]);

  const hasPrevPage = pageNumber > 1;
  const hasNextPage = !!(games && pageNumber < games.totalPages);

  const handlePrevButton = () => {
    if (hasPrevPage) setPageNumber(pageNumber - 1);
  };

  const handleNextButton = () => {
    if (hasNextPage) setPageNumber(pageNumber + 1);
  };

  return (
    <Modal
      height={622}
      isOpen={isOpen}
      onClose={onClose}
      paddingCloseIcon={18}
      width={950}
    >
      {isDesktop
        ? <ModalHistoryDesktop
          games={games}
          handleNextButton={handleNextButton}
          handlePrevButton={handlePrevButton}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
        : <ModalHistoryMobile
          games={games}
          handleNextButton={handleNextButton}
          handlePrevButton={handlePrevButton}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      }

      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Modal>
  );
};

export default ModalHistory;
