export enum ButtonVariant {
  OUTLINED = "OUTLINED",
  OUTLINED_PINK = "OUTLINED_PINK",
  PINK = "PINK",
}

export enum ButtonSize {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
  TINY = "TINY",
}
