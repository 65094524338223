/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC } from "react";
import type { GetManyArtistsResponse } from "services/artist";
import type { GameEntity } from "services/game";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import useAuth from "hooks/useAuth";
import BoxWrapper from "../BoxWrapper";
import Card from "../Card";
import Snackbar, { SnackbarType } from "components/Snackbar";
import { Actions, NB_ARTISTS_PER_GAME } from "constants/game";
import gameService from "services/game";
import userService from "services/user";

/* styles imports */
import { CardWrapper, Main, Text } from "./index.styles";

type SubmitViewProps = {
  artists: GetManyArtistsResponse;
  choices: (Actions | null)[];
  setChoices: (choices: (Actions | null)[]) => void;
  setGameResult: (results: GameEntity | null) => void;
};

const SubmitView:FC<SubmitViewProps> = ({
  artists,
  choices,
  setChoices,
  setGameResult
}) => {
  const { setUser } = useAuth();
  const ref = useRef<SnackbarRefProps>(null);

  const handleChoiceSelection = (action: Actions, cardIndex: number): void => {
    if (action === undefined || cardIndex === undefined) return;

    const mappedChoices = choices.map((choice, index) => {
      return choice === action && index !== cardIndex
        ? null
        : index === cardIndex
          ? action
          : choice;
    });

    setChoices(mappedChoices);

    const nbSelectedChoices = mappedChoices
      .filter((choice) => typeof choice === "string")?.length;

    if (nbSelectedChoices === NB_ARTISTS_PER_GAME) {
      const [
        fuckedArtist,
        marriedArtist,
        killedArtist
      ] = [
        artists.items[mappedChoices.indexOf(Actions.FUCK)],
        artists.items[mappedChoices.indexOf(Actions.MARRY)],
        artists.items[mappedChoices.indexOf(Actions.KILL)]
      ];

      if (!fuckedArtist || !marriedArtist || !killedArtist) {
        ref.current?.open("You must select an action for each artist to submit your choices!");

        return;
      }

      gameService.createGame({
        hash: artists.hash,
        fuckedArtistId: fuckedArtist.id,
        marriedArtistId: marriedArtist.id,
        killedArtistId: killedArtist.id
      })
        .then((gameResult) => {
          setGameResult(gameResult);

          userService.getUserData()
            .then(setUser)
            .catch((err) => ref.current?.open(err?.response?.data?.message));
        })
        .catch((err) => ref.current?.open(err?.response?.data?.message));
    }
  };

  return (
    <Main>
      <BoxWrapper alignItems="flex-end" height={25}>
        <Text>
          Who would you fuck, marry... kill?
        </Text>
      </BoxWrapper>
      <CardWrapper>
        {[...Array(NB_ARTISTS_PER_GAME)].map((_, index) => (
          <Card
            key={index}
            artist={artists.items[index]}
            cardIndex={index}
            choice={choices[index]}
            handleChoiceSelection={handleChoiceSelection}
          />
        ))}
      </CardWrapper>
      <BoxWrapper alignItems="flex-start" height={25} />

      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Main>
  );
};

export default SubmitView;
