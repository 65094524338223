/* modules imports */
import styled, { keyframes } from "styled-components";

/* project files imports */
import Button from "components/Button";
import { devices } from "constants/style";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
`;

export const Image = styled.img`
  width: 275px;

  @media ${devices.sm} { width: 300px; }
  @media ${devices.lg} { width: 350px; }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const fadeIn = keyframes`
  100% { opacity: 1; }
`;

type StyledButtonProps = {
  index: number;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  opacity: 0;
  animation: ${fadeIn} .3s forwards;
  animation-delay: ${({ index }) => index * .15}s;
`;

export const BoldText = styled.span`
  font-family: var(--font-content-bold);
`;
