/* modules imports */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

/* project files imports */
import { SnackbarType } from "./constants";
import { SnackbarMessage } from "./constants";
import { toSmallDateTime } from "utils/date";

/* styles imports */
import { Content, DateTime, Message, StyledCloseIcon, StyledSnackbar } from "./index.styles";

type SnackbarProps = {
  timeout?: number;
  type?: SnackbarType;
};

export type SnackbarRefProps = {
  open: (message: string) => void;
};

const Snackbar = forwardRef<SnackbarRefProps, SnackbarProps>(
  ({ timeout = 5000, type = SnackbarType.INFO }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [timeoutId, setTimeoutId] = useState<number>(0);

    const handleSnackbarOpening = (message: string) => {
      if (isOpen) return;

      setIsOpen(true);
      setMessage(message);
      setTimeoutId(
        window.setTimeout(
          (): void => {
            setIsOpen(false);
            setMessage(null);
            setTimeoutId(0);
          },
          timeout
        )
      );
    };

    const handleSnackbarClosing = () => {
      setIsOpen(false);
      setMessage(null);
      setTimeoutId(0);
      clearTimeout(timeoutId);
    };

    useEffect(() => {
      return () => {
        // clear ongoing timeout on component unmount
        if (timeoutId !== 0) clearTimeout(timeoutId);
      };
    }, [timeoutId]);

    useImperativeHandle(ref, () => ({
      open: handleSnackbarOpening
    }));

    return (
      <StyledSnackbar isOpen={isOpen} timeout={timeout} type={type}>
        <Content>
          <DateTime>{toSmallDateTime(new Date())}</DateTime>
          <Message>{message || SnackbarMessage.get(type)}</Message>
          <StyledCloseIcon onClick={handleSnackbarClosing} />
        </Content>
      </StyledSnackbar>
    );
  }
);

export { SnackbarType };
export default Snackbar;
