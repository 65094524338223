/* modules imports */
import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  /* height: 60px !important; */
  height: inherit !important;
  width: 100%;
  top: 0;
  z-index: 1;
`;

export const Logo = styled.img`
  position: absolute;
  width: 85px;
  top: 13px;
  left: 20px;
`;

export const Menu = styled.img`
  position: absolute;
  width: 25px;
  top: 23px;
  right: 20px;
  cursor: pointer;
`;
