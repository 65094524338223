/* types imports */
import type { FC, HTMLAttributes } from "react";
import type { GetManyGamesResponse } from "services/game";

/* project files imports */
import Emoji, { EmojiSize } from "components/Emoji";
import Button, { ButtonSize, ButtonVariant } from "components/Button";
import useAuth from "hooks/useAuth";
import useResponsivity from "hooks/useResponsivity";
import { limitSize } from "utils/string";
import { toSmallDateTime } from "utils/date";
import { Emojis } from "constants/game";

/* styles imports */
import {
  Artist,
  Card,
  Content,
  DateTime,
  Footer,
  NextButton,
  NoHistory,
  PrevButton,
  ShareButtonWrapper
} from "./index.styles";

type ModalHistoryMobileProps = {
  games: GetManyGamesResponse | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  handlePrevButton: () => void;
  handleNextButton: () => void;
} & HTMLAttributes<HTMLElement>;

const ModalHistoryMobile:FC<ModalHistoryMobileProps> = ({
  games,
  hasPrevPage,
  hasNextPage,
  handlePrevButton,
  handleNextButton
}) => {
  const { user } = useAuth();
  const { isMobile } = useResponsivity();
  const contentId = "history-content";
  const artistNameMaxLength = isMobile ? 20 : 30;

  return (
    <>
      <Content id={contentId}>
        {
          games?.items?.length
            ? games.items.map((entry, index) => (
              <Card key={index}>
                <DateTime>
                  {toSmallDateTime(entry.createdAt)}
                </DateTime>
                <Artist>
                  <Emoji emoji={Emojis.SMIRKING_FACE} size={EmojiSize.SMALL} />
                  {" "}{limitSize(entry.fuckedArtist?.name, artistNameMaxLength)}
                </Artist>
                <Artist>
                  <Emoji emoji={Emojis.RING} size={EmojiSize.SMALL} />
                  {" "}{limitSize(entry.marriedArtist?.name, artistNameMaxLength)}
                </Artist>
                <ShareButtonWrapper>
                  <div>
                    <Emoji emoji={Emojis.KITCHEN_KNIFE} size={EmojiSize.SMALL} />
                    {" "}{limitSize(entry.killedArtist?.name, artistNameMaxLength)}
                  </div>
                  <Button
                    onClick={
                      () => {
                        window.open(
                          `${process.env.REACT_APP__API__BASE_URL}/s/games/${entry.id}`,
                          "_blank"
                        );
                      }
                    }
                    size={ButtonSize.TINY}
                    variant={ButtonVariant.PINK}
                  >
                    Share
                  </Button>
                </ShareButtonWrapper>
              </Card>
            ))
            : (
              <NoHistory>
                You have to play at least 1 game to see your history!
              </NoHistory>
            )
        }
      </Content>
      <Footer>
        <PrevButton
          isHidden={!hasPrevPage}
          onClick={handlePrevButton}
        >
          {"<"}
        </PrevButton>
        total games played: {user?.totalGamesPlayed || 0}
        <NextButton
          isHidden={!hasNextPage}
          onClick={handleNextButton}
        >
          {">"}
        </NextButton>
      </Footer>
    </>
  );
};

export default ModalHistoryMobile;
