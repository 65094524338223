/* modules imports */
import styled, { css, keyframes } from "styled-components";

/* project files imports */
import { artistPicturePlaceholder } from "utils/placeholder";
import { devices } from "constants/style";

const fadeIn = keyframes`
  100% { opacity: 1; }
`;

type ContentProps = {
  hasAnimation: boolean;
  cardIndex: number;
};

export const Content = styled.div<ContentProps>`
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;

  ${({ hasAnimation, cardIndex }) => hasAnimation && css`
    opacity: 0;
    animation: ${fadeIn} .5s forwards;
    animation-delay: ${cardIndex * .25}s;
  `}

  @media ${devices.sm} { width: 170px; }
  @media ${devices.lg} { width: 225px; }
`;

type BoxWrapperProps = {
  isHidden: boolean;
  alignItems: string;
};

export const BoxWrapper = styled.div<BoxWrapperProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: center;
  height: 45px;
  width: 100%;

  ${({ isHidden }) => isHidden && "display: none;"}  
`;

type ImageWrapperProps = {
  hasBorder: boolean;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background-color: var(--color-white);
  border-radius: 10px;
  overflow: hidden;

  @media ${devices.sm} { height: 170px; }
  @media ${devices.lg} { height: 225px; }

  ${({ hasBorder }) => hasBorder && "box-shadow: 0 0 0 1px var(--color-black) inset"}
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: var(--font-content);
  text-align: center;

  @media ${devices.sm} { font-size: 16px; }
  @media ${devices.lg} { font-size: 18px; }
`;

export const TextResult = styled.span`
  font-size: 14px;
  font-family: var(--font-content);
  text-align: center;

  @media ${devices.sm} {
    font-size: 16px;
  }

  @media ${devices.lg} {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    white-space: pre-wrap;
  }
`;

export const Bold = styled.span`
  font-family: var(--font-content-bold);
`;

const flip = keyframes`
  0%, 80% { transform: rotateY(360deg); }
`;

type AnimatedQuestionMarkProps = {
  cardIndex: number;
};

export const AnimatedQuestionMark = styled.span<AnimatedQuestionMarkProps>`
  color: var(--color-red);
  font-size: 54px;
  user-select: none;
  animation: ${flip} 3s infinite;
  animation-delay: ${({ cardIndex }) => .2 * cardIndex}s;

  @media ${devices.sm} { font-size: 67px; }
  @media ${devices.lg} { font-size: 80px; }
`;

type ImageProps = {
  image: string | null;
};

export const Image = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  background-image: url(${({ image }) => image}), url(${artistPicturePlaceholder()});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 35px);
  background-color: var(--color-white);
  box-shadow: 0 0 0 1px var(--color-black) inset;
  border-radius: 10px;

  @media ${devices.sm} {
    grid-template-rows: none;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(45px, auto);    
  }
`;

export const Button = styled.div`
  cursor: pointer;
  
  &:nth-of-type(2) {
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
  }

  @media ${devices.sm} {
    &:nth-of-type(2) {
      border-left: 1px solid var(--color-black);
      border-right: 1px solid var(--color-black);
    }
  }
`;

type EmojiWrapperProps = {
  isSelected: boolean;
};

export const EmojiWrapper = styled.div<EmojiWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:active {
    filter: grayscale(0%);
    opacity: 100%;
  }
  
  ${({ isSelected }) => !isSelected && `
    filter: grayscale(100%);
    opacity: 50%;
  `}

  @media ${devices.sm} {
    &:hover {
      filter: grayscale(0%);
      opacity: 100%;
    }
  }
`;
