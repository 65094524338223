/* modules imports */
import { css } from "styled-components";

export default css`
  :root {
    --vh: ${window.innerHeight * 0.01}px;

    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-grey: #C7C7C7;
    --color-red: #FF0000;
    --color-pink: #FFA7D5;

    --font-content-fallback: monospace, system-ui, sans-serif;
    --font-content: "FT88", var(--font-content-fallback);
    --font-content-bold: "FT88 Bold", var(--font-content-fallback);
  }
`;
