/* modules imports */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

/* types imports */
import type { FC } from "react";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import { ButtonSize, ButtonVariant } from "components/Button";
import Snackbar, { SnackbarType } from "components/Snackbar";
import logo from "assets/images/logo-big.png";
import { Status as AuthStatus } from "constants/auth";

/* styles imports */
import { BoldText, ButtonWrapper, Content, Image, StyledButton } from "./index.styles";

type LoginPageProps = {
  authStatus: AuthStatus;
};

const LoginPage:FC<LoginPageProps> = ({ authStatus }) => {
  const ref = useRef<SnackbarRefProps>(null);
  const navigate = useNavigate();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const location = new URL(window.location?.toString());
    const err = location?.searchParams?.get("error");

    if (err) {
      navigate("/");

      if (err === "InternalServerError") {
        ref.current?.open("The server encountered an unexpected error");
      }
    } else if (authStatus === AuthStatus.ERRORED) {
      ref.current?.open("An error has occurred.\nYou may need to log in again.");
    }
  }, []);

  const services = [
    { name: "Spotify", path: "/auth/spotify" },
    { name: "Deezer", path: "/auth/deezer" },
    { name: "Last.fm", path: "/auth/lastfm" }
  ];

  return (
    <Content>
      <Image
        alt="fmk big logo"
        onLoad={() => setIsImageLoaded(true)}
        src={logo}
      />
      {isImageLoaded && <ButtonWrapper>
        {services.map((service, index) => (
          <StyledButton
            key={index}
            index={index}
            onClick={
              () => {
                if (service.path) {
                  window.location.assign(
                    process.env.REACT_APP__API__BASE_URL + service.path
                  );
                }
              }
            }
            size={ButtonSize.LARGE}
            variant={ButtonVariant.OUTLINED}
          >
            Authenticate using <BoldText>{service.name}</BoldText>
          </StyledButton>
        ))}
      </ButtonWrapper>}

      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Content>
  );
};

export default LoginPage;
