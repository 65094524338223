/* types imports */
import type { AxiosResponse } from "axios";
import type { ArtistProfileEntity } from "./artist-profile";
import type { GameEntity } from "./game";
import type { UserEntity } from "./user";

/* project files imports */
import api from "utils/api";

export type ArtistEntity = {
  id: string;
  name: string;
  nbGames?: number;
  profiles?: ArtistProfileEntity[];
  fuckedGames?: GameEntity[];
  marriedGames?: GameEntity[];
  killedGames?: GameEntity[];
  userTops?: UserEntity[];
};

export type GetManyArtistsResponse = {
  hash: string;
  items: ArtistEntity[];
};

/**
 * Get 3 random artists from user's top artists list,
 * that have not been used in a previous game
 * @returns {Promise<GetManyArtistsResponse>}
 */
const getManyArtists = (): Promise<GetManyArtistsResponse> => {
  return api.get("/artists")
    .then((res: AxiosResponse) => res.data);
};

export default { getManyArtists };
