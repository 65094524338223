/* modules imports */
import { useState } from "react";

/* types imports */
import type { FC, HTMLAttributes } from "react";

/* project files imports */
import Modal from "./Modal";
import logo from "assets/images/logo-small.png";
import IconMenu from "assets/images/icon-menu.svg";

/* styles imports */
import { Content, Logo, Menu } from "./index.styles";

type TabProps = {
  icon: string;
  label: string;
  onClick: () => void;
};

type MobileMenuProps = {
  tabs: TabProps[];
  selectedIndex: number;
} & HTMLAttributes<HTMLElement>;

const MobileMenu:FC<MobileMenuProps> = ({ tabs, selectedIndex }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleMenuOpening = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClosing = () => {
    setIsMenuOpen(false);
  };

  const mappedTabs = tabs.map((tab: TabProps) => ({
    ...tab,
    onClick: () => {
      tab.onClick();
      handleMenuClosing();
    }
  }));

  return (
    <>
      <Content>
        <Logo
          alt="fmk small logo"
          draggable={false}
          src={logo}
        />
        <Menu
          alt="menu icon"
          draggable={false}
          onClick={handleMenuOpening}
          src={IconMenu}
        />
      </Content>
      <Modal
        isOpen={isMenuOpen}
        onClose={handleMenuClosing}
        selectedIndex={selectedIndex}
        tabs={mappedTabs}
      />
    </>
  );
};

export default MobileMenu;
