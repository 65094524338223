/* types imports */
import type { AxiosResponse } from "axios";
import type { ArtistEntity } from "./artist";
import type { GameEntity } from "./game";

/* project files imports */
import type Services from "constants/service";
import api from "utils/api";

export type UserEntity = {
  id: string;
  name: string;
  service: Services;
  profileId: string;
  profileUrl: string;
  profilePicture: string | null;
  refreshedAt: Date;
  canPlayGame?: boolean;
  canRefreshData?: boolean;
  totalGamesPlayed?: number;
  mostFuckedArtist?: ArtistEntity;
  mostMarriedArtist?: ArtistEntity;
  mostKilledArtist?: ArtistEntity;
  games?: GameEntity[];
  topArtists?: ArtistEntity[];
};

/**
 * Get user data
 * @returns {Promise<User>}
 */
const getUserData = (): Promise<UserEntity> => {
  return api.get("/user")
    .then((res: AxiosResponse) => res.data);
};

/**
 * Refresh user data
 * @returns {Promise<User>}
 */
const refreshUserData = (): Promise<UserEntity> => {
  return api.post("/user/refresh")
    .then((res: AxiosResponse) => res.data);
};

export default { getUserData, refreshUserData };
