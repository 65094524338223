/* modules imports */
import styled from "styled-components";

/* project files imports */
import { devices } from "constants/style";
import { profilePicturePlaceholder } from "utils/placeholder";

export const Profile = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: 16px;

  @media ${devices.sm} { font-size: 18px; }
  @media ${devices.lg} { font-size: 20px; }
`;

type PictureProps = {
  image?: string | null;
};

export const Picture = styled.div<PictureProps>`
  width: 116px;
  min-width: 116px;
  height: 116px;
  min-height: 116px;
  border-radius: 50%;
  background-color: var(--color-pink);
  background-image: url(${({ image }) => image}), url(${profilePicturePlaceholder()});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${devices.sm} {
    width: 130px;
    min-width: 130px;
    height: 130px;
    min-height: 130px;
  }

  @media ${devices.lg} {
    width: 145px;
    min-width: 145px;
    height: 145px;
    min-height: 145px;
  }
`;

export const Username = styled.span`
  font-size: 23px;
  padding: 5px;

  @media ${devices.sm} {
    font-size: 25px;
    padding: 7px;
  }

  @media ${devices.lg} {
    font-size: 28px;
    padding: 8px;
  }
`;

export const Bold = styled.span`
  font-family: var(--font-content-bold);
`;

export const MostPlayedArtists = styled.div`
  padding-top: 20px;

  @media ${devices.sm} { padding-top: 25px; }
  @media ${devices.lg} { padding-top: 30px; }
`;

export const MostPlayedArtist = styled.span`
  display: inline;

  @media ${devices.lg} {
    display: flex;
    white-space: pre-wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  row-gap: 8px;

  @media ${devices.sm} {
    font-size: 16px;
    row-gap: 10px;
  }
`;

export const RefreshUserData = styled.span`
  font-size: 15px;
  cursor: pointer;

  @media ${devices.sm} { font-size: 16px; }
  @media ${devices.lg} { font-size: 18px; }

  &:hover {
    text-decoration: underline; 
  }
`;
