/* modules imports */
import styled, { css } from "styled-components";

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border: 0;
  border-spacing: 0;
`;

export const Tr = styled.tr`
  top: 0;
  display: block;
  border-collapse: collapse;
  border-top: 0;
  border-bottom: 2px solid var(--color-black);
`;

export const TrNoHistory = styled.tr`
  display: block;
  width: 100%;
  height: 100%;
`;

export const cellStyle = css`
  height: 50px;
  font-family: var(--font-content);
  font-weight: normal;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 15px;
  
  border-collapse: collapse;
  border-left: 2px solid var(--color-black);
  border-right: 2px solid var(--color-black);
  &:first-child { border-left: 0; }
  &:last-child { border-right: 0; }

  &:nth-of-type(1) { width: 197px; }
  &:nth-of-type(2) { width: 186px; }
  &:nth-of-type(3) { width: 186px; }
  &:nth-of-type(4) { width: 254px; }
`;

export const Th = styled.th`
  ${cellStyle};
  font-size: 20px;
  position: relative;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Td = styled.td`
  ${cellStyle};
  font-size: 17px;
  width: 100%;
`;

export const TdNoHistory = styled.td`
  width: 100%;
  height: 100%;
  overflow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--color-grey);
`;

export const ShareButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tbody = styled.tbody`
  display: block;
  width: 100%;
  height: 520px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50px;
  border-top: 2px solid var(--color-black);
  font-size: 18px;
`;

type NavButtonProps = {
  isHidden: boolean;
};

const NavButton = styled.div<NavButtonProps>`
  position: absolute;
  cursor: pointer;
  display: ${({ isHidden }) => isHidden ? "none" : "block"};
`;

export const PrevButton = styled(NavButton)`
  left: 25px;
`;

export const NextButton = styled(NavButton)`
  right: 25px;
`;
