/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC, HTMLAttributes } from "react";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import Modal from "../index";
import useAuth from "hooks/useAuth";
import useResponsivity from "hooks/useResponsivity";
import userService from "services/user";
import Button, { ButtonSize, ButtonVariant } from "components/Button";
import Emoji, { EmojiSize } from "components/Emoji";
import Snackbar, { SnackbarType } from "components/Snackbar";
import { toSmallDateTime } from "utils/date";
import { limitSize } from "utils/string";
import { Emojis } from "constants/game";

/* styles imports */
import {
  Bold,
  Content,
  Footer,
  MostPlayedArtist,
  MostPlayedArtists,
  Picture,
  Profile,
  RefreshUserData,
  Username
} from "./index.styles";

type ModalProfileProps = {
  isOpen: boolean;
  onClose: () => void;
} & HTMLAttributes<HTMLElement>;

const ModalProfile:FC<ModalProfileProps> = (props) => {
  const { user, setUser } = useAuth();
  const { isDesktop, isMobile } = useResponsivity();
  const errRef = useRef<SnackbarRefProps>(null);
  const updateRef = useRef<SnackbarRefProps>(null);

  const handleRefreshUserData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    userService.refreshUserData()
      .then((results) => {
        setUser(results);
        updateRef.current?.open(
          "Your profile has been updated, new artists may have been picked up!"
        );
      })
      .catch((err) => errRef.current?.open(err?.response?.data?.message));
  };

  const mostPlayedArtists = [
    { action: "fucked"  , emoji: Emojis.SMIRKING_FACE , ...user?.mostFuckedArtist },
    { action: "married" , emoji: Emojis.RING          , ...user?.mostMarriedArtist },
    { action: "killed"  , emoji: Emojis.KITCHEN_KNIFE , ...user?.mostKilledArtist }
  ];

  return (
    <Modal {...props}>
      <Profile>
        <Content>
          <Picture image={user?.profilePicture} />
          <Username>{user?.name || "_"}</Username>
          <span>
            <Bold>{user?.totalGamesPlayed || 0}</Bold> games played
          </span>
          {
            user?.totalGamesPlayed
              ? (
                <MostPlayedArtists>
                  {mostPlayedArtists.map((artist, index) => (
                    <MostPlayedArtist key={index}>
                      You {artist.action}{" "}
                      {limitSize(artist.name, isDesktop ? 28 : isMobile ? 10 : 19)}{" "}
                      <Bold>{artist.nbGames}</Bold> times{" "}
                      <Emoji
                        emoji={artist.emoji}
                        size={isDesktop ? EmojiSize.MEDIUM : EmojiSize.SMALL}
                      />
                      <br />
                    </MostPlayedArtist>
                  ))}
                </MostPlayedArtists>
              ) : null
          }
        </Content>
        <Footer>
          <Button
            onClick={
              () => {
                window.open(
                  `${process.env.REACT_APP__API__BASE_URL}/s/user-profile`,
                  "_blank"
                );
              }
            }
            size={ButtonSize.SMALL}
            variant={ButtonVariant.OUTLINED_PINK}
          >
            Share
          </Button>
          {user?.canRefreshData && (
            <RefreshUserData onClick={handleRefreshUserData}>
              <Emoji emoji={Emojis.PARTY_POPPER} size={EmojiSize.SMALL} />
              {" "}click here to update your top artists!{" "}
              <Emoji emoji={Emojis.PARTY_POPPER} size={EmojiSize.SMALL} />
            </RefreshUserData>
          )}
          {user?.canRefreshData && user?.refreshedAt && (
            <span>
              last update: <>{toSmallDateTime(user.refreshedAt)}</>
            </span>
          )}
        </Footer>
      </Profile>

      <Snackbar ref={errRef} type={SnackbarType.ERROR} />
      <Snackbar ref={updateRef} type={SnackbarType.INFO} />
    </Modal>
  );
};

export default ModalProfile;
