/* modules imports */
import { createGlobalStyle } from "styled-components";
import "normalize.css";

/* project files imports */
import Fonts from "./fonts";
import Variables from "./variables";
import Globals from "./globals";

export default createGlobalStyle`
  ${Fonts};
  ${Variables};
  ${Globals};
`;
