/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC } from "react";
import type { GetManyArtistsResponse } from "services/artist";
import type { UserEntity } from "services/user";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import BoxWrapper from "../BoxWrapper";
import Card from "../Card";
import { Emojis, NB_ARTISTS_PER_GAME } from "constants/game";
import artistService from "services/artist";
import userService from "services/user";
import Snackbar, { SnackbarType } from "components/Snackbar";
import { ButtonSize, ButtonVariant } from "components/Button";
import Emoji, { EmojiSize } from "components/Emoji";
import { toSmallDateTime } from "utils/date";

/* styles imports */
import {
  BoldText,
  ButtonText,
  ButtonWrapper,
  CardWrapper,
  Main,
  RefreshUserData,
  RefreshUserDataWrapper,
  Text
} from "./index.styles";

type PlayViewProps = {
  setArtists: (artist: GetManyArtistsResponse | null) => void;
  user: UserEntity | null;
  setUser: (user: UserEntity | null) => void;
};

const PlayView:FC<PlayViewProps> = ({ setArtists, user, setUser }) => {
  const errRef = useRef<SnackbarRefProps>(null);
  const updateRef = useRef<SnackbarRefProps>(null);

  const handlePlayButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    artistService.getManyArtists()
      .then(setArtists)
      .catch((err) => errRef.current?.open(err?.response?.data?.message));
  };

  const handleRefreshUserData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    userService.refreshUserData()
      .then((results) => {
        setUser(results);
        updateRef.current?.open(
          "Your profile has been updated, new artists may have been picked up!"
        );
      })
      .catch((err) => errRef.current?.open(err?.response?.data?.message));
  };

  return (
    <Main>
      <BoxWrapper alignItems="flex-end" height={48}>
        <Text>
          Hi <BoldText>{user?.name}</BoldText>!
          You have played <BoldText>{user?.totalGamesPlayed}</BoldText> games so far
        </Text>
      </BoxWrapper>
      <CardWrapper>
        {[...Array(NB_ARTISTS_PER_GAME)].map((_, index) => (
          <Card key={index} cardIndex={index} />
        ))}
      </CardWrapper>
      <BoxWrapper alignItems="flex-start" height={48}>
        <ButtonWrapper
          onClick={handlePlayButton}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.OUTLINED}
        >
          <ButtonText>
            Play
          </ButtonText>
        </ButtonWrapper>
      </BoxWrapper>
      <RefreshUserDataWrapper>
        {user?.canRefreshData && (
          <RefreshUserData onClick={handleRefreshUserData}>
            <Emoji emoji={Emojis.PARTY_POPPER} size={EmojiSize.SMALL} />
            {" "}click here to update your top artists!{" "}
            <Emoji emoji={Emojis.PARTY_POPPER} size={EmojiSize.SMALL} />
          </RefreshUserData>
        )}
        {user?.canRefreshData && user?.refreshedAt && (
          <span>
              last update: <>{toSmallDateTime(user.refreshedAt)}</>
          </span>
        )}
      </RefreshUserDataWrapper>

      <Snackbar ref={errRef} type={SnackbarType.ERROR} />
      <Snackbar ref={updateRef} type={SnackbarType.INFO} />
    </Main>
  );
};

export default PlayView;
