/* project files imports */
import api from "utils/api";

/**
 * Destroy user session
 */
const logout = (): Promise<void> => {
  return api.post("/auth/logout");
};

export default { logout };
